<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search requests"
            type="text"
          />
          <!--          to add a service request-->
          <b-button
            v-b-modal.addRequestModal
            variant="primary"
            @click="title = ''; description = ''; type = ''; instructions = ''; show_instructions = true; update = false;"
          >
            <feather-icon icon="PlusIcon" />
            Add Service Request
          </b-button>
          <!--          added a checkboc to unhide all rejected request-->
          <b-form-checkbox
            v-model="hideRejected"
            class="d-flex align-items-center mt-1 justify-content-start"
          >
            {{ hideRejected ? 'Show Rejected Requests' : 'Hide Rejected Requests' }}}
          </b-form-checkbox>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="requests"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span
            v-if="props.column.field === 'title'"
            class="d-flex justify-content-between align-items-center"
          >
            {{ props.row.title }}
            <b-button
              variant="link"
              size="sm"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('requestDetailsModal')"
            >View</b-button>
          </span>
          <span
            v-else-if="props.column.field === 'status'"
          >
            <b-badge
              style="text-transform: capitalize"
              :variant="`${props.row.status === 'approved' ? 'primary' : (props.row.status === 'unchecked' ? 'light-primary' : 'light-danger')}`"
            >{{ props.row.status }}</b-badge>
          </span>
          <span
            v-else-if="props.column.field === 'action'"
            class="d-flex align-items-center"
          >
            <b-button
              v-if="props.row.status !== 'approved'"
              size="sm"
              variant="primary"
              class="d-flex align-items-center mr-1"
              @click="updateStatus(props.row.id, 'approved')"
            >
              Accept
            </b-button>
            <b-button
              v-if="props.row.status !== 'rejected'"
              size="sm"
              variant="outline-danger"
              @click="updateStatus(props.row.id, 'rejected')"
            >
              Reject
            </b-button>
            <!-- <b-button
              size="sm"
              variant="flat-danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-button> -->
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        v-if="requests[selectedRow] && requests[selectedRow].hasOwnProperty('description') "
        id="requestDetailsModal"
        hide-footer
        title="Service Details"
      >
        <div>
          <p>
            {{ requests[selectedRow].description }}
          </p>
          <hr>
          <div
            v-if="requests[selectedRow].comments && requests[selectedRow].comments.length"
          >
            <span
              class="font-medium-1"
            >Comments</span>
            <div
              v-for="(comment, index) in requests[selectedRow].comments"
              :key="index"
              class="border-top mt-1"
            >
              <p class="mt-1 font-weight-bold d-flex justify-content-between text-capitalize">
                {{ comment.created_by }}
                <span><feather-icon icon="CalendarIcon" />
                  {{ comment.created_at }}</span>
              </p>
              <p class="pl-50">
                {{ comment.message }}
              </p>
            </div>
          </div>
        </div>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>

import {
  BBadge, BButton, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
  },
  data() {
    return {
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      hideRejected: true,
      columns: [
        {
          label: 'Service',
          field(row) {
            return row.partnerservices.title
          },
        },
        {
          label: 'Request Date',
          field(row) {
            return new Date(row.created_at)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'UTC',
              })
          },
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      requests: [],
      servicetypes: [],
      mutationLoading: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned: 'light-secondary',
        unassigned: 'light-warning',
        unchecked: 'light-secondary',
        'in-process': 'light-primary',
        resolved: 'light-success',
        closed: 'light-danger',
        low: 'light-success',
        medium: 'light-info',
        high: 'light-warning',
        urgent: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    // filter to unhide rejected request
    filteredRows() {
      return this.hideRejected ? this.requests.filter(r => r.status !== 'rejected') : this.requests
    },
  },
  methods: {
    updateStatus(id, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestStatus {
            update_partnerservices_request_by_pk(pk_columns: {id: ${id}}, _set: {status: "${status}"}) {
              id
            }
          },
        `,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.type,
        children: node.title,
      }
    },
  },
  apollo: {
    requests: {
      query() {
        // removed incubator id , it is not going tp use here
        return gql`
        query partnerservices_request {
          partnerservices_request(
            where: {users_organizationtable: {users_associationtables: {id: {_eq: ${this.$route.params.id}}}}}
          ) {
            id
            rating
            remarks
            status
            created_at
            partnerservices {
              title
            }
          }
        }`
      },
      update: data => data.partnerservices_request,
    },

  },
}
</script>
